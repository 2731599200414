<template>
    <main>
        <div class="ui center aligned container">
            <div class="ui hidden section divider"></div><div class="ui hidden section divider"></div><div class="ui hidden section divider"></div>
            <div class="ui medium image">
                <router-link :to="{ name: 'Home' }"><img class="logo" src="~@/assets/images/logo/1991spwg.png"/></router-link>
            </div>
            <div class="ui centered raised approval card" :class="{loading}">
                <div class="content">
                    <div class="center aligned header">
                        <div class="ui hidden medium divider"></div>
                        <i class="big purple shield alternate icon"></i>
                        <div class="ui hidden medium divider"></div>
                        <div>New Device Login</div>
                    </div>
                    <div class="ui hidden divider"></div>
                    <div class="center aligned description">
                        <p>Login attempt by <strong>{{device.created_by|displayname}}</strong> on new device detected.</p>
                    </div>
                    <div class="ui hidden divider"></div>
                    <div class="ui two column middle aligned unstackable grid" v-if="!isBlankObject(device)">
                        <div class="left aligned column">
                            <label><strong>Device</strong></label>
                            <p class="info meta">
                                {{device_info}}
                            </p>
                            <label><strong>Operating System</strong></label>
                            <p class="os meta">
                                {{os_info}}
                            </p>
                        </div>
                        <div class="center aligned column">
                            <template v-if="device.info.device.type === 'desktop'">
                                <i class="huge desktop icon"></i>
                            </template>
                            <template v-else-if="device.info.device.type === 'smartphone' || device.info.device.type === 'feature phone'">
                                <i class="huge mobile alternate icon"></i>
                            </template>
                            <template v-else-if="device.info.device.type === 'tablet' || device.info.device.type === 'phablet'">
                                <i class="huge tablet alternate icon"></i>
                            </template>
                            <template v-else>
                                <i class="huge laptop house icon"></i>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="extra content">
                    <div class="ui two buttons">
                        <button class="ui basic teal button" type="button" @click="approve"><i class="check icon"></i> Approve</button>
                        <button class="ui basic red button" type="button" @click="reject"><i class="times icon"></i> Reject</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
export default {
    data() {
        return {
            device_id: this.$route.params.device_id,
            device: {},
            loading: true
        };
    },
    created() {
        this.$http.get(`devices/${this.device_id}`).then((response) => {
            const device = response.data;

            this.$http.get(`users/${device.created_by}`).then((response) => {
                device.created_by = response.data;
                this.device = device;
            }).catch((error) => {
                console.error(error);
                this.$toasted.error("Unable to check requestor info. Please try again later.", {
                    duration: 3000
                });
            }).finally(() => {
                this.loading = false;
            });
        }).catch((error) => {
            this.loading = false;

            console.error(error);
            this.$toasted.error("Unable to check device info. Please try again later.", {
                duration: 3000
            });
        });
    },
    computed: {
        device_info() {
            let device_info = "";
            if(this.device && !this.isBlankObject(this.device)) {
                device_info = `${this.device.info?.client?.name} on ${this.device.info?.device?.type}`;

                if(this.device.info?.device?.brand || this.device.info?.device?.model) {
                    device_info += ` (${this.device.info.device.brand} ${this.device.info.device.model})`;
                }
            }

            return device_info.trim();
        },
        os_info() {
            let os_info = "";
            if(this.device && !this.isBlankObject(this.device)) {
                os_info = `${this.device.info?.os?.name} ${this.device.info?.os?.platform} ${this.device.info?.os?.version}`;
            }

            return os_info.trim();
        }
    },
    methods: {
        approve() {
            this.device.verified = true;

            this.loading = true;
            this.$http.put(`devices/${this.device_id}`, {
                verified_by: this._user.id,
                verified: true
            }).then((response) => {
                this.$toasted.show("Device approved successfully. Thank you.", {
                    action: {
                        text: "OK",
                        onClick: (e, toast) => {
                            this.$router.push({
                                name: "Home"
                            });

                            toast.goAway(0);
                        }
                    }
                });
            }).catch((error) => {
                this.loading = false;

                console.error(error);
                this.$toasted.error("Unable to approve device. Please try again later.", {
                    duration: 3000
                });
            });
        },
        reject() {
            this.$confirm({
                title: "Reject Device",
                message: `Are you sure you want to reject login on new device <strong>${this.device_info}</strong>?`,
                button: {
                    yes: "Yes",
                    no: "Cancel"
                },
                callback: (confirmed) => {
                    if(confirmed) {
                        this.loading = true;
                        this.$http.delete(`devices/${this.device_id}`).then((response) => {
                            this.$toasted.show("Device rejected successfully. Thank you.", {
                                action: {
                                    text: "OK",
                                    onClick: (e, toast) => {
                                        this.$router.push({
                                            name: "Home"
                                        });

                                        toast.goAway(0);
                                    }
                                }
                            });
                        }).catch((error) => {
                            this.loading = false;

                            console.error(error);
                            this.$toasted.error("Unable to reject device. Please try again later.", {
                                duration: 3000
                            });
                        });
                    }
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.ui.approval.card {
    overflow: hidden;
    width: 320px;
}

@media only screen and (max-width: 480px) {
    .ui.approval.card {
        box-shadow: none;
    }
}
</style>